import { atom, Box, useRecoilState } from "components/common";
import useCartFunctions from "hooks/use-cart";
import { MouseEvent, ReactNode, useState } from "react";
import { useDashboardLoaderState } from "states/dashboardLoaderState";
import { useDrawerState, useSetDrawerState } from "states/drawerState";
import { navbarOptions } from "./constants";
import { usePathname } from "hooks/use-pathname";

const headerHeightState = atom({
  key: "HeaderHeightState",
  default: 0,
});
const loginDialog = atom({
  key: "LoginDailogState",
  default: { open: false, isBecomeDealer: false },
});
const menuState = atom<null | HTMLElement>({
  key: "MenuState",
  default: null,
});

export default function useFunctions() {
  const pathname = usePathname();
  const [open] = useDrawerState();
  const setOpen = useSetDrawerState();
  const [loader] = useDashboardLoaderState();
  const [headerHeight, setHeaderHeight] = useRecoilState(headerHeightState);
  const [loginDialogOpen, setLoginDialogOpen] = useRecoilState(loginDialog);
  const [anchorEl, setAnchorEl] = useRecoilState(menuState);
  const { cart, addToCart, removeFromCart, clearCart } = useCartFunctions();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLoginDialogToggle = (isBecomeDealer?: true) => {
    if (isBecomeDealer) {
      return setLoginDialogOpen((prev) => ({
        open: true,
        isBecomeDealer: true,
      }));
    }
    setLoginDialogOpen((prev) => ({
      ...prev,
      open: !prev.open,
      isBecomeDealer: false,
    }));
  };

  return {
    pathname,
    navbarOptions,
    anchorEl,
    loader,
    open,
    setOpen,
    handleDrawerToggle,
    headerHeight,
    setHeaderHeight,
    loginDialogOpen,
    handleLoginDialogToggle,
    cart,
    addToCart,
    removeFromCart,
    clearCart,
  };
}
