import {
  HomeRounded as MenuItem0,
  TungstenRounded as MenuItem1,
  ContactMailRounded as MenuItem6,
  LoginRounded as MenuItem8,
} from "@mui/icons-material";
import { SetterOrUpdater } from "recoil";
const icons = {
  MenuItem0,
  MenuItem1,
  MenuItem6,
  MenuItem8,
};
export interface DropDownProps {
  open: boolean;
  categories: any[];
  index: number;
  link_name: string;
  label: string;
}

export interface NavbarOptionsProps {
  label: string;
  variant: "contained" | "text" | "outlined";
  categories?: any[];
  href?: string;
  type: "modal" | "link" | "node";
  link_name?:
    | "deaf-bonce"
    | "sundown-audio"
    | "dd-audio"
    | "xs-power"
    | "nemesis-audio";
}
export interface NavbarOptionsNewProps {
  label: string;
  variant: "contained" | "text" | "outlined";
  categories?: any[];
  href?: string;
  input: "dropdown" | "button" | "link";
  link_name?: string;
}

export interface HeaderDesktopLinksItemProps {
  item: NavbarOptionsProps;
  index: number;
  handleDropDownToggle: (data: NavbarOptionsProps, index: number) => void;
  setDropDown: SetterOrUpdater<DropDownProps>;
}

export const navbarOptions: NavbarOptionsProps[] = [
  // {
  //   label: "Bulb Finder",
  //   variant: "contained",
  //   type: "modal",
  // },
  {
    label: "Deaf Bonce",
    variant: "text",
    type: "node",
    link_name: "deaf-bonce",
    categories: [
      {
        id: "amplifiers",
        title: "amplifiers",
      },

      {
        id: "speakers",
        title: "speakers",
      },
      {
        id: "subwoofers",
        title: "subwoofers",
      },

      {
        id: "cables",
        title: "cables",
      },

      {
        id: "promos",
        title: "promos",
      },
      {
        id: "accessories",
        title: "accessories",
      },

      {
        id: "recones",
        title: "recones",
      },
    ],
  },

  {
    label: "DD Audio",
    variant: "text",
    type: "node",
    link_name: "dd-audio",
    categories: [
      {
        id: "amplifiers",
        title: "amplifiers",
      },

      {
        id: "speakers",
        title: "speakers",
      },
      {
        id: "subwoofers",
        title: "subwoofers",
      },

      {
        id: "cables",
        title: "cables",
      },
      {
        id: "accessories",
        title: "accessories",
      },
    ],
  },

  {
    label: "Sundown Audio",
    variant: "contained",
    type: "node",
    link_name: "sundown-audio",
    categories: [
      {
        id: "amplifiers",
        title: "amplifiers",
      },

      {
        id: "speakers",
        title: "speakers",
      },
      {
        id: "subwoofers",
        title: "subwoofers",
      },

      {
        id: "cables",
        title: "cables",
      },

      {
        id: "accessories",
        title: "accessories",
      },
    ],
  },
  {
    label: "XS Power Batteries",
    variant: "text",
    type: "node",
    link_name: "xs-power",
    categories: [
      {
        id: "agm-batteries",
        title: "AGM batteries",
      },
      {
        id: "racing-batteries",
        title: "Racing batteries",
      },
      {
        id: "powersports-batteries",
        title: "Powersports batteries",
      },
      {
        id: "vintage-batteries",
        title: "Vintage batteries",
      },
      {
        id: "xp-series-batteries",
        title: "XP Series batteries",
      },

      {
        id: "xs-flex-cable",
        title: "FLEX CABLE",
      },
      {
        id: "lithium-intellichargers",
        title: "Lithium IntelliCHARGERS",
      },
      {
        id: "agm-intellichargers",
        title: "AGM IntelliCHARGERS",
      },
      {
        id: "combo-kits",
        title: "Combo Kits",
      },
      {
        id: "superbanks",
        title: "SUPERBANKS",
      },
      {
        id: "powerbanks",
        title: "POWERBANKS",
      },
      {
        id: "isolator-kits",
        title: "Isolator Kits",
      },
      {
        id: "wiring-kits",
        title: "Wiring Kits",
      },
      {
        id: "fuses",
        title: "Fuses",
      },
      {
        id: "adaptors",
        title: "TERMINAL ADAPTORS",
      },
      {
        id: "voltage-control-modules",
        title: "Voltage Control Modules",
      },
      {
        id: "hold-downs",
        title: "Hold Downs",
      },
    ],
  },
  {
    label: "Nemesis Audio",
    variant: "text",
    type: "node",
    link_name: "nemesis-audio",
    categories: [
      {
        id: "subwoofer",
        title: "Subwoofer",
      },
      {
        id: "accessories",
        title: "Accessories",
      },
      {
        id: "amplifier",
        title: "Amplifier",
      },
      {
        id: "battery",
        title: "Battery",
      },
      {
        id: "speaker",
        title: "Speaker",
      },
      {
        id: "tweeter",
        title: "Tweeter",
      },
    ],
  },
  {
    label: "Contact",
    variant: "text",
    href: "/contact-us",
    type: "link",
  },
];
export const navbarOptionsNew: NavbarOptionsNewProps[] = [
  {
    label: "Shop by Brand",
    variant: "contained",
    input: "dropdown",
    // link_name: "deaf-bonce",
  },

  {
    label: "LEDs",
    variant: "text",
    input: "button",
    link_name: "leds",
  },
  {
    label: "Subwoofers",
    variant: "text",
    input: "button",
    link_name: "subwoofers",
  },

  {
    label: "Amplifiers",
    variant: "text",
    input: "button",
    link_name: "amplifiers",
  },
  {
    label: "Speakers",
    variant: "text",
    input: "button",
    link_name: "speakers",
  },
  {
    label: "Tweeters",
    variant: "text",
    input: "button",
    link_name: "nemesis-audio",
  },
  {
    label: "Stereos",
    variant: "text",
    input: "button",
    link_name: "stereos",
  },
  {
    label: "Cables",
    variant: "text",
    input: "button",
    link_name: "cables",
  },
  {
    label: "Bass Processors",
    variant: "text",
    input: "button",
    link_name: "bass-processors",
  },
  {
    label: "Contact",
    variant: "outlined",
    href: "/contact-us",
    input: "link",
  },
];
export const drawerOptions = [
  {
    id: 0,
    title: "Home",
    url: "/",
    icon: icons.MenuItem0,
    type: "link",
  },
  // {
  //   id: 1,
  //   title: "Bulb Finder",
  //   icon: icons.MenuItem1,
  //   type: "button",
  // },
  {
    id: 2,
    title: "Deaf Bonce",
    link_name: "deaf-bonce",
    type: "dropdown",
    categories: [
      {
        id: "amplifiers",
        title: "amplifiers",
      },

      {
        id: "speakers",
        title: "speakers",
      },
      {
        id: "subwoofers",
        title: "subwoofers",
      },

      {
        id: "cables",
        title: "cables",
      },

      {
        id: "promos",
        title: "promos",
      },
      {
        id: "accessories",
        title: "accessories",
      },

      {
        id: "recones",
        title: "recones",
      },
    ],
  },
  {
    id: 3,
    title: "DD Audio",
    link_name: "dd-audio",
    type: "dropdown",
    categories: [
      {
        id: "amplifiers",
        title: "amplifiers",
      },

      {
        id: "speakers",
        title: "speakers",
      },
      {
        id: "subwoofers",
        title: "subwoofers",
      },

      {
        id: "cables",
        title: "cables",
      },
      {
        id: "accessories",
        title: "accessories",
      },
    ],
  },
  {
    id: 4,
    title: "Sundown Audio",
    type: "dropdown",
    link_name: "sundown-audio",
    categories: [
      {
        id: "amplifiers",
        title: "amplifiers",
      },

      {
        id: "speakers",
        title: "speakers",
      },
      {
        id: "subwoofers",
        title: "subwoofers",
      },

      {
        id: "cables",
        title: "cables",
      },

      {
        id: "accessories",
        title: "accessories",
      },
    ],
  },
  {
    id: 5,
    title: "XS Power",
    type: "dropdown",
    link_name: "xs-power",
    categories: [
      {
        id: "agm-batteries",
        title: "AGM batteries",
      },
      {
        id: "racing-batteries",
        title: "Racing batteries",
      },
      {
        id: "powersports-batteries",
        title: "Powersports batteries",
      },
      {
        id: "vintage-batteries",
        title: "Vintage batteries",
      },
      {
        id: "xp-series-batteries",
        title: "XP Series batteries",
      },

      {
        id: "xs-flex-cable",
        title: "XS FLEX CABLE",
      },
      {
        id: "lithium-intellichargers",
        title: "Lithium IntelliCHARGERS",
      },
      {
        id: "agm-intellichargers",
        title: "AGM IntelliCHARGERS",
      },
      {
        id: "combo-kits",
        title: "Combo Kits",
      },
      {
        id: "superbanks",
        title: "SUPERBANKS",
      },
      {
        id: "powerbanks",
        title: "POWERBANKS",
      },
      {
        id: "isolator-kits",
        title: "Isolator Kits",
      },
      {
        id: "wiring-kits",
        title: "Wiring Kits",
      },
      {
        id: "fuses",
        title: "Fuses",
      },

      {
        id: "adaptors",
        title: "TERMINAL ADAPTORS",
      },
      {
        id: "voltage-control-modules",
        title: "Voltage Control Modules",
      },
      {
        id: "hold-downs",
        title: "Hold Downs",
      },
    ],
  },
  {
    id: 6,
    title: "Nemesis Audio",
    type: "dropdown",
    link_name: "nemesis-audio",
    categories: [
      {
        id: "Subwoofer",
        title: "Subwoofer",
      },
      {
        id: "accessories",
        title: "Accessories",
      },
      {
        id: "amplifier",
        title: "Amplifier",
      },
      {
        id: "battery",
        title: "Battery",
      },
      {
        id: "speaker",
        title: "Speaker",
      },
      {
        id: "tweeter",
        title: "Tweeter",
      },
    ],
  },
  {
    id: 7,
    title: "Contact",
    url: "/contact-us",
    type: "link",
  },
];
