import { Box, Collapse, Menu, MenuItem, Paper, Toolbar } from "@mui/material";
import {
  DropDownProps,
  navbarOptionsNew,
  NavbarOptionsProps,
} from "layouts/main-layout/ts/constants";
import { atom, useRecoilState } from "recoil";
import { HeaderDesktopLinksNewWrapper } from "./index.styled";
import { CustomLinkButton, ArrowDropDownRounded } from "components/common";
import useFunctions from "components/pages/home-page/home-brands/ts/useFunctions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 100;

export default function HeaderDesktopLinksNew() {
  const navigate = useNavigate();
  const { loading, data } = useFunctions();
  console.log(loading, data);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderDesktopLinksNewWrapper>
      {navbarOptionsNew?.map((item, index) => (
        <CustomLinkButton
          key={index}
          variant={item.variant}
          endIcon={item.input === "dropdown" ? <ArrowDropDownRounded /> : null}
          onClick={item.input === "dropdown" ? handleClick : undefined}
          href={
            item.input === "link"
              ? item.href
              : item.input === "button"
              ? `/products/${item.link_name}`
              : ""
          }
        >
          {item.label}
        </CustomLinkButton>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={open}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          },
        }}
      >
        {loading && data.length === 0 ? (
          <MenuItem>Loading</MenuItem>
        ) : (
          data
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ name, id }) => (
              <MenuItem
                key={id}
                onClick={() => {
                  navigate(`/products/${name}`);
                  handleClose();
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {name}
              </MenuItem>
            ))
        )}
      </Menu>
    </HeaderDesktopLinksNewWrapper>
  );
}
