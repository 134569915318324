import React, { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import axiosInstance from "utils/axios";

interface Brand {
  name: string;
  id: string;
  image: string;
}

const loadingAtom = atom<Boolean>({
  key: "BrandLoading",
  default: true,
});
const errorAtom = atom<string>({
  key: "BrandError",
  default: "",
});

const brandAtom = atom<Brand[]>({
  key: "BrandAtom",
  default: [],
});

export default function useFunctions() {
  const [loading, setLoading] = useRecoilState(loadingAtom);
  const [data, setData] = useRecoilState(brandAtom);
  const [error, setError] = useRecoilState(errorAtom);

  const getData = async () => {
    await axiosInstance
      .get("/brands")
      .then((e) => {
        const { data } = e;
        setData(data.brands);
        setLoading(false);
      })
      .catch((err) => {
        if (err.message) {
          setError(err.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    loading,
    data,
    error,
  };
}
