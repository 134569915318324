import { Box, Collapse, Paper, Toolbar } from "@mui/material";
import {
  DropDownProps,
  navbarOptions,
  NavbarOptionsProps,
} from "layouts/main-layout/ts/constants";
import { atom, useRecoilState } from "recoil";
import HeaderDesktopLinksItem from "./header-desktop-links-item";
import HeaderDesktopLinksAccordionComponent from "./header-desktop-links-item/header-desktop-links-item-accordion";

const dropDownInitial = {
  open: false,
  index: 0,
  link_name: "",
  categories: [],
  label: "",
};

const dropDownState = atom<DropDownProps>({
  key: "dd-nav-state",
  default: dropDownInitial,
});

export default function HeaderDesktopLinks() {
  const [dropDown, setDropDown] = useRecoilState(dropDownState);

  const handleDropDownToggle = (data: NavbarOptionsProps, index: number) => {
    setDropDown((prev) => {
      if (prev.index === index) {
        return dropDownInitial;
      } else {
        return {
          open: true,
          index: index,
          label: data.label,
          link_name: data.link_name || "",
          categories: data.categories || [],
        };
      }
    });
  };

  return (
    <Toolbar
      sx={{
        margin: "auto",
        maxWidth: 1500,
        justifyContent: "center",
        background: "white",
        columnGap: 5,
        rowGap: 1,
        py: 2,
        px: 3,
        minHeight: "0 !important",
      }}
    >
      {navbarOptions?.map((item, index) => (
        <HeaderDesktopLinksItem
          key={index}
          item={item}
          index={index}
          handleDropDownToggle={handleDropDownToggle}
          setDropDown={setDropDown}
        />
      ))}
      <Box
        sx={{
          position: "absolute",
          top: 70,
          width: "100%",
        }}
      >
        <Collapse in={dropDown.open} timeout={400}>
          <Paper
            sx={{
              py: 2,
              px: 3,
              justifyContent: "center",
              color: "black",
              minHeight: "0 !important",
              backgroundColor: "#f9f9f9",
              boxShadow: 3,
            }}
          >
            <HeaderDesktopLinksAccordionComponent
              dropDown={dropDown}
              closeDropDown={() => setDropDown(dropDownInitial)}
            />
          </Paper>
        </Collapse>
      </Box>
    </Toolbar>
  );
}
