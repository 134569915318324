import { ThemeProvider } from "@mui/material";
import AuthErrorBoundary from "components/auth-error-boundary/AuthErrorBoundary";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import { theme } from "theme";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ToastProvider } from "utils/toast";
import { Analytics } from "@vercel/analytics/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthErrorBoundary>
            <App />
          </AuthErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
      <ToastProvider />
    </RecoilRoot>
    <Analytics />
  </React.StrictMode>
);

reportWebVitals();
