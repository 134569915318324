import { styled, Toolbar } from "@mui/material";

export const HeaderDesktopLinksNewWrapper = styled(Toolbar)(({ theme }) => ({
  margin: "auto",
  maxWidth: 1500,
  justifyContent: "center",
  background: "white",
  columnGap: theme.spacing(3),
  padding: theme.spacing(1, 3),
  minHeight: "0 !important",
}));
