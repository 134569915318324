import { Box, ScreenLoader, useMediaQuery } from "components/common";
import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import MainDrawer from "./drawer";
import Header from "./header";
import useFunctions from "./ts/useFunctions";

import LoginDialog from "pages/auth";
import { theme } from "theme";
import Footer from "components/layouts/footer";

export default function MainLayout() {
  const {
    pathname,
    loader,
    headerHeight,
    loginDialogOpen,
    cart,
    addToCart,
    clearCart,
  } = useFunctions();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const hideFooterOnPathname = ["/cart", "/checkout"];

  const shouldHideFooter =
    hideFooterOnPathname.includes(pathname) ||
    pathname.startsWith("/products") ||
    pathname.startsWith("/product-details");
  return (
    <>
      <Header />
      {matchDownMD && <MainDrawer />}
      <Box
        component="main"
        sx={{
          width: "100%",
          maxWidth: 2000,
          margin: "auto",
          mt: `${headerHeight}px`,
        }}
      >
        <Suspense fallback={<ScreenLoader />}>
          <Outlet />
        </Suspense>
      </Box>
      {!shouldHideFooter && <Footer />}

      <LoginDialog />
    </>
  );
}
