import {
  Box,
  LocalMallRounded,
  MuiLoadingButton,
  Typography,
} from "components/common";
import useProductsFunctions from "components/pages/products-page/ts/useProductsFunctions";

import useCartFunctions from "hooks/use-cart";
import useFunctions from "layouts/authed-layout/ts/useFunctions";
import { forwardRef, useState } from "react";
import { Link } from "react-router-dom";
import { ProductCardWrapper } from "./index.styled";

const ProductCard = forwardRef<HTMLDivElement, any>((props: any, ref) => {
  const { data } = props;
  const { id, title, thumbnail, images, retail_price, wholesale_price } = data;
  const { open } = useProductsFunctions();
  const { addToCart } = useCartFunctions();
  const { dealerSwitch } = useFunctions();

  const [imgLoading, setImgLoading] = useState(true);
  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const disabled = dealerSwitch ? !wholesale_price : !retail_price;

  return (
    <ProductCardWrapper
      ref={ref}
      imgLoading={imgLoading}
      xl={open ? 4 : 3}
      lg={open ? 4 : 3}
      md={open ? 4 : 3}
      sm={4}
      xs={12}
    >
      <Box className="main">
        <Box
          component={(props: any) => (
            <Link
              to={`/product-details/${id}`}
              style={{ textDecoration: "none" }}
            >
              {props.children}
            </Link>
          )}
        >
          <Box className="img-container">
            <Box
              component="img"
              src={Array.isArray(images) ? images[0] : thumbnail}
              alt={Array.isArray(images) ? images[0] : thumbnail}
              onLoad={handleImageLoad}
            />
          </Box>

          <Box>
            <Typography className="title" fontSize="16px" variant="subtitle2">
              {title}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: disabled ? "rgba(0, 0, 0, 0.26)" : "#00E852",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {disabled
              ? "Price unavailable"
              : `$${dealerSwitch ? wholesale_price : retail_price}`}
          </Typography>
        </Box>
        <MuiLoadingButton
          startIcon={disabled ? null : <LocalMallRounded />}
          sx={{
            width: "100%",
            mt: 2,
          }}
          disabled={disabled}
          onClick={() =>
            addToCart({
              id,
              price: dealerSwitch ? wholesale_price : retail_price,
              title,
              data,
              quantity: 1,
              subtotal: dealerSwitch ? wholesale_price : retail_price,
            })
          }
        >
          {disabled ? "Unavailable" : "Add to cart"}
        </MuiLoadingButton>
      </Box>
    </ProductCardWrapper>
  );
});

export default ProductCard;
